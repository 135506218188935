<template>
  <div>
    <AppNav v-if="isInApp">쿠폰 다운로드 받으세요</AppNav>
    <div class="wrapper" :class="{ 'is-app': appView }">
      <!-- <Confeti /> -->
      <header class="header">
        <img class="title-img" :src="header.titleImage.src" :alt="header.titleImage.alt" />
        <p class="desc">
          <span class="line" v-for="(line, idx) in header.text" :key="idx">
            {{ line }}
          </span>
        </p>
        <img
          class="tudal-img"
          src="https://res.tutoring.co.kr/res/images/component/coupondownload/img_coupon_tudal.gif"
          alt=""
          aria-hidden="true"
        />
      </header>
      <main class="main">
        <section class="section" v-for="couponGroup in couponList" :key="couponGroup?.idx">
          <div v-if="couponGroup.titleImage">
            <img
              class="title"
              :src="couponGroup.titleImage?.src"
              :alt="couponGroup.titleImage?.alt"
              :style="{
                width: `${couponGroup.titleImage?.alt.length * 6}%`,
              }"
            />
            <ul>
              <li class="coupon" v-for="(coupon, idx) in couponGroup.coupons" :key="idx">
                <Coupon
                  :myCouponInfo="myCouponInfo"
                  :coupon="coupon"
                  :fetchData="fetchData"
                  :userKey="userKey"
                  :couponIdx="coupon.dcIdx"
                  :idx="idx"
                />
              </li>
            </ul>
          </div>
        </section>
      </main>
      <footer class="notice">
        <p class="title">{{ notice.title }}</p>
        <ul>
          <li class="item" v-for="(line, idx) in notice.text" :key="idx">
            {{ line }}
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { native } from '@/tools';
import inappTools from '@/common/inappTools';
import api from '@/common/api';
import AppNav from '../../components/AppNav.vue';
// import Confeti from "../../components/Confeti.vue";
import Coupon from './Coupon.vue';

export default {
  name: 'PageDownloadCoupons',
  components: {
    AppNav,
    // Confeti,
    Coupon,
  },
  data() {
    return {
      couponList: [],
      myCouponInfo: [],
      header: { titleImage: { src: null, alt: null }, text: [] },
      notice: { title: null, text: [] },
    };
  },

  computed: {
    userKey() {
      return localStorage.getItem('key');
    },
    isInApp() {
      return inappTools.isInApp();
    },
  },

  created() {
    this.fetchData();
    this.getMyCoupons();
  },

  methods: {
    parseHeader(rawHeader) {
      return rawHeader.reduce(
        (acc, item) => {
          if (item.type === 'titleImage') {
            const { titleImage: src, imageAlt: alt } = item.data;
            return {
              ...acc,
              titleImage: { src, alt },
            };
          }
          if (item.type === 'text') {
            item.data.forEach((_) => acc.text.push(_.text));
            return acc;
          }
          return null;
        },
        {
          titleImage: { src: null, alt: null },
          text: [],
        },
      );
    },
    /* eslint-disable no-param-reassign */
    parseCouponList(rawCouponList) {
      return rawCouponList.reduce((acc, couponGroup) => {
        acc.push(
          couponGroup.reduce((newCouponGroup, item, idx) => {
            if (item.type === 'titleImage') {
              const { titleImage: src, imageAlt: alt } = item.data;
              newCouponGroup.idx = newCouponGroup.idx || idx;
              newCouponGroup.titleImage = { src, alt };
            } else if (item.type === 'couponWithImage') {
              const { dcIdx } = item.data;
              newCouponGroup.coupons = newCouponGroup.coupons || [];
              if (!this.isInApp) {
                if (dcIdx === 588) {
                  const convertUrl = '/v2/plan/single';
                  item.data.url = convertUrl;
                  newCouponGroup.coupons.push(item.data);
                } else if (dcIdx !== 610) {
                  newCouponGroup.coupons.push(item.data);
                }
              } else {
                newCouponGroup.coupons.push(item.data);
              }
            }
            return newCouponGroup;
          }, {}),
        );
        return acc;
      }, []);
    },
    /* eslint-enable no-param-reassign */
    parseNotice(rawNotice) {
      return rawNotice[0].data.reduce(
        (acc, item) => {
          const [key, value] = Object.entries(item)[0];
          if (key === 'title') {
            return { ...acc, ...item };
          }
          if (key === 'text') {
            acc.text.push(value);
            return acc;
          }
          return null;
        },
        {
          title: null,
          text: [],
        },
      );
    },
    /* eslint-disable no-param-reassign */
    async getMyCoupons() {
      try {
        await api.get('/app/users/coupon/discount', { key: this.userKey }).then((res) => {
          if (res.result === 2000) {
            this.myCouponInfo = res.data?.discountCoupon;
          }
        });
      } catch (error) {
        console.debug(error);
        native.close();
      }
    },
    async fetchData() {
      try {
        await api.get('/app/etc/v2/couponBook', { key: this.userKey, channel: 2 }).then((res) => {
          const { data } = res;
          const groups = data.reduce(
            (g, d) => {
              if (d.type === 'gutter') g.idx += 1;
              else {
                if (!g.data[g.idx]) {
                  g.data[g.idx] = [];
                }
                g.data[g.idx].push(d);
              }
              return g;
            },
            {
              idx: 0,
              data: [],
            },
          );
          this.header = this.parseHeader(groups.data[0]);
          this.couponList = this.parseCouponList(groups.data.slice(1, groups.idx));
          this.notice = this.parseNotice(groups.data[groups.idx]);
        });
      } catch (error) {
        console.error(error);
        native.close();
      }
    },
    /* eslint-enable no-param-reassign */
  },
};
</script>

<style lang="scss" scoped>
@mixin desktop($minimum_width: 1025px) {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin tablet($minimum_width: 768px) {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin small_mobile($minimum_width: 280px) {
  @media (max-width: 280px) {
    @content;
  }
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 175px;
  overflow: hidden;
  background: #06bf9d;
  &.is-app {
    padding-top: 42px;
  }
}

.header {
  position: relative;
  z-index: 1;
  padding: 0 40px;
  margin-top: 40px;
  @include desktop {
    max-width: 360px;
    margin: 0px auto;
  }
  .title-img {
    width: 100%;
  }
  .desc {
    margin-top: 15px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #ffffff;
  }
  .line {
    display: block;
  }
  .tudal-img {
    position: absolute;
    right: 27.55px;
    top: -72%;
    width: 50%;
    // animation: swing infinite 1.6s linear;
  }
}

.main {
  padding: 80px 40px 100px;
}

.section {
  margin-bottom: 80.5px;
  @include desktop {
    max-width: 360px;
    margin: 0px auto 80.5px auto;
  }
  .title {
    margin-bottom: 20px;
  }
  .btn {
    display: block;
    width: 100%;
    height: 56px;
    margin-top: 30px;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 10px;
  }
  .coupon {
    & + .coupon {
      margin-top: 10px;
    }
  }
}

.section:last-child {
  margin-bottom: 0px;
}

.event {
  .btn {
    background: #f24b4b;
  }
}

.month {
  .btn {
    background: #5d04d9;
  }
}

.coupon {
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4vw;
  overflow: hidden;
  height: calc((100vw - 80px) * (300 / 840));
  @include tablet {
    border-radius: 5vw;
  }
  @include small_mobile {
    border-radius: 3vw;
  }
  @include desktop {
    max-width: 360px;
    height: calc((360px) * (300 / 840));
    border-radius: 20px;
  }
  & + .coupon {
    margin-top: 10px;
  }
}

@keyframes swing {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

.notice {
  padding: 30px 20px;
  background: #03604f;
  .title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -1px;
    color: #daf5f0;
    @include desktop {
      max-width: 360px;
      margin: 0px auto;
    }
  }
  .item {
    font-size: 10px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #06bf9d;
    opacity: 0.7;
    @include desktop {
      max-width: 360px;
      margin: 0px auto;
    }
  }
}
</style>
