import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupToast = _resolveComponent("PopupToast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      src: $data.hasCoupon ? $data.downloadCompleteImage : $data.downloadImage,
      alt: "쿠폰 이미지",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.clickCoupon())),
      style: { width: '100%', cursor: 'pointer' }
    }, null, 8 /* PROPS */, _hoisted_1),
    _createVNode(_component_PopupToast, { isPopup: $data.isPopup }, {
      default: _withCtx(() => [
        _createElementVNode("p", { innerHTML: $data.popUpMessage }, null, 8 /* PROPS */, _hoisted_2)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["isPopup"])
  ], 64 /* STABLE_FRAGMENT */))
}