import { render } from "./Coupon.vue?vue&type=template&id=26ccf6f6"
import script from "./Coupon.vue?vue&type=script&lang=ts"
export * from "./Coupon.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "26ccf6f6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('26ccf6f6', script)) {
    api.reload('26ccf6f6', script)
  }
  
  module.hot.accept("./Coupon.vue?vue&type=template&id=26ccf6f6", () => {
    api.rerender('26ccf6f6', render)
  })

}

script.__file = "src/page/PageDownloadCoupons/Coupon.vue"

export default script