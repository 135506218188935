<template>
  <img
    :src="hasCoupon ? downloadCompleteImage : downloadImage"
    alt="쿠폰 이미지"
    @click="clickCoupon()"
    :style="{ width: '100%', cursor: 'pointer' }"
  />
  <PopupToast :isPopup="isPopup"><p v-html="popUpMessage"></p></PopupToast>
</template>

<script lang="ts">
import { storage, native, tracker } from '@/tools';
import api from '@/common/api';
import PopupToast from '../../components/PopupToast.vue';
import inappTools from '../../common/inappTools';

export default {
  name: 'Coupon',
  components: {
    PopupToast,
  },
  props: {
    myCouponInfo: {
      type: Array,
      required: false,
    },
    coupon: {
      type: Object,
      required: true,
    },
    userKey: {
      type: String,
      required: false,
    },
    idx: {
      type: Number,
      required: true,
    },
    couponIdx: {
      type: Number,
      required: false,
    },
  },
  data() {
    const { type, url, dcIdx, downloadImage, downloadCompleteImage } = this.coupon;
    return {
      type,
      url,
      dcIdx,
      downloadImage,
      downloadCompleteImage,
      hasCoupon: 0,
      isPopup: false,
      popUpMessage: '',
    };
  },
  mounted() {
    this.isUseCoupon();
  },
  computed: {
    isInApp() {
      return inappTools.isInApp();
    },
  },

  methods: {
    async downloadCoupon() {
      try {
        const result = await api.post(`/app/users/coupon/discount/${this.dcIdx}?key=${this.userKey}`);
        if (!this.isPopup) {
          if (result.data.result === 1000) {
            this.popUpMessage = result.data.message;
          } else if (result.result === 1000) {
            this.popUpMessage = result.message;
          } else if (this.hasCoupon !== 1) {
            this.popUpMessage = '쿠폰이 다운로드 되었습니다.';
            this.hasCoupon = 1;
          }
          this.isPopup = true;
          setTimeout(() => {
            this.isPopup = false;
          }, 2000);
        }
      } catch (e) {
        console.error(e);
      }
    },
    goUseCoupon() {
      if (!this.isInApp) {
        window.location.href = `https://${document.location.host}${this.url}`;
      } else if (this.type === 'router') {
        storage.session.set('routeAfterWebViewClosed', this.url);
        native.close();
      } else if (this.type === 'openWebView') {
        this.$router.push(this.url.replace('/v2', ''));
      } else if (this.type === 'openWebviewBuy') {
        inappTools.goToGoodsPage(this.url);
      } else if (this.type === 'openBrowser') {
        inappTools.openWebBroswerNoPay(this.url);
      }
    },
    isUseCoupon() {
      const coupon = this.myCouponInfo ? this.myCouponInfo.filter((data) => data.dcCouponIdx === this.couponIdx) : null;
      if (coupon.length === 1) {
        const { status } = coupon[0];
        // eslint-disable-next-line no-return-assign, no-unused-expressions
        status === 'I' ? (this.hasCoupon = 1) : null;
      }
    },
    clickCoupon() {
      if (!this.isInApp) {
        if (this.dcIdx === 763 || this.dcIdx === 764) {
          const trackerAction = `Web_downloadCoupons_black${this.idx + 1}`;
          tracker.recordUserAction(trackerAction);
        } else {
          const trackerAction = `Web_downloadCoupons_event${this.idx + 1}`;
          tracker.recordUserAction(trackerAction);
        }
      }
      if (!this.userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else if (this.hasCoupon === 1) {
        console.log('hasCoupondd', this.hasCoupon);
        this.goUseCoupon();
      } else {
        this.downloadCoupon();
      }
    },
  },
};
</script>
