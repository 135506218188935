
import { native } from '@/tools';
import inappTools from '@/common/inappTools';
import api from '@/common/api';
import AppNav from '../../components/AppNav.vue';
// import Confeti from "../../components/Confeti.vue";
import Coupon from './Coupon.vue';

export default {
  name: 'PageDownloadCoupons',
  components: {
    AppNav,
    // Confeti,
    Coupon,
  },
  data() {
    return {
      couponList: [],
      myCouponInfo: [],
      header: { titleImage: { src: null, alt: null }, text: [] },
      notice: { title: null, text: [] },
    };
  },

  computed: {
    userKey() {
      return localStorage.getItem('key');
    },
    isInApp() {
      return inappTools.isInApp();
    },
  },

  created() {
    this.fetchData();
    this.getMyCoupons();
  },

  methods: {
    parseHeader(rawHeader) {
      return rawHeader.reduce(
        (acc, item) => {
          if (item.type === 'titleImage') {
            const { titleImage: src, imageAlt: alt } = item.data;
            return {
              ...acc,
              titleImage: { src, alt },
            };
          }
          if (item.type === 'text') {
            item.data.forEach((_) => acc.text.push(_.text));
            return acc;
          }
          return null;
        },
        {
          titleImage: { src: null, alt: null },
          text: [],
        },
      );
    },
    /* eslint-disable no-param-reassign */
    parseCouponList(rawCouponList) {
      return rawCouponList.reduce((acc, couponGroup) => {
        acc.push(
          couponGroup.reduce((newCouponGroup, item, idx) => {
            if (item.type === 'titleImage') {
              const { titleImage: src, imageAlt: alt } = item.data;
              newCouponGroup.idx = newCouponGroup.idx || idx;
              newCouponGroup.titleImage = { src, alt };
            } else if (item.type === 'couponWithImage') {
              const { dcIdx } = item.data;
              newCouponGroup.coupons = newCouponGroup.coupons || [];
              if (!this.isInApp) {
                if (dcIdx === 588) {
                  const convertUrl = '/v2/plan/single';
                  item.data.url = convertUrl;
                  newCouponGroup.coupons.push(item.data);
                } else if (dcIdx !== 610) {
                  newCouponGroup.coupons.push(item.data);
                }
              } else {
                newCouponGroup.coupons.push(item.data);
              }
            }
            return newCouponGroup;
          }, {}),
        );
        return acc;
      }, []);
    },
    /* eslint-enable no-param-reassign */
    parseNotice(rawNotice) {
      return rawNotice[0].data.reduce(
        (acc, item) => {
          const [key, value] = Object.entries(item)[0];
          if (key === 'title') {
            return { ...acc, ...item };
          }
          if (key === 'text') {
            acc.text.push(value);
            return acc;
          }
          return null;
        },
        {
          title: null,
          text: [],
        },
      );
    },
    /* eslint-disable no-param-reassign */
    async getMyCoupons() {
      try {
        await api.get('/app/users/coupon/discount', { key: this.userKey }).then((res) => {
          if (res.result === 2000) {
            this.myCouponInfo = res.data?.discountCoupon;
          }
        });
      } catch (error) {
        console.debug(error);
        native.close();
      }
    },
    async fetchData() {
      try {
        await api.get('/app/etc/v2/couponBook', { key: this.userKey, channel: 2 }).then((res) => {
          const { data } = res;
          const groups = data.reduce(
            (g, d) => {
              if (d.type === 'gutter') g.idx += 1;
              else {
                if (!g.data[g.idx]) {
                  g.data[g.idx] = [];
                }
                g.data[g.idx].push(d);
              }
              return g;
            },
            {
              idx: 0,
              data: [],
            },
          );
          this.header = this.parseHeader(groups.data[0]);
          this.couponList = this.parseCouponList(groups.data.slice(1, groups.idx));
          this.notice = this.parseNotice(groups.data[groups.idx]);
        });
      } catch (error) {
        console.error(error);
        native.close();
      }
    },
    /* eslint-enable no-param-reassign */
  },
};
